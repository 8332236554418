<template>
  <div>
    <vx-card actionable class="cardx" title="Batch Reset">
      <vs-row vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a class="a-icon" href.prevent @click="showTreeBox">
              My Focus
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu
              class="dropdown-login customDropDown"
              style="padding: 0px"
            >
              <!-- <focus-search /> -->
              <treeselect v-model="initSelected" :options="treeDatafocus" />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a class="a-icon" href.prevent @click="showTreeBox">
              Levels
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown">
              <treeselect
                v-model="initSelectedLevels"
                :multiple="true"
                :options="treeDataLevels"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a class="a-icon" href.prevent @click="showTreeBox">
              SPOC
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown">
              <treeselect
                v-model="initSelectedBDE"
                :multiple="true"
                :options="treeDataBDE"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a class="a-icon" href.prevent @click="showTreeBox">
              Courses
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown">
              <treeselect
                v-model="initSelectedCourses"
                :multiple="true"
                :options="treeDataCourses"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
          <date-range-picker
            ref="picker"
            :opens="'left'"
            :locale-data="{
              firstDay: 1,
              format: 'DD-MMM-YYYY',
              customRangeLabel: 'Custom Range'
            }"
            :minDate="null"
            :maxDate="null"
            :singleDatePicker="false"
            :timePicker="false"
            :timePicker24Hour="true"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="false"
            v-model="dateRange"
            style="width: 200px; margin-right:2%"
            @update="analyzePagination(1)"
          ></date-range-picker>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
          <vs-dropdown class="gap" vs-custom-content vs-trigger-click :key="dropdownKey">
            <vs-button
              radius
              color="dark"
              type="gradient"
              icon="search"
            ></vs-button>
            <vs-dropdown-menu class="loginx">
              <vs-row vs-w="12" style="margin-bottom: 3%">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="9"
                >
                  <v-select
                    :filterable="false"
                    :options="mwb_names"
                    @search="autoCompleteName"
                    v-model="value1"
                    style="width:100%"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="search('name')"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="9"
                >
                  <v-select
                    :options="citiesSearchOptions"
                    v-model="citySearch"
                    style="width:100%"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="search('name')"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" style="margin-bottom: 3%">
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="9"
                >
                  <v-select
                    v-if="spocLogged"
                    :options="SpocCityPrefilled"
                    v-model="SpocPrefilled"
                    :clearable="false"
                    style="margin-top:10%; margin-right: 1%"
                  />
                  <vs-input
                    type="text"
                    label-placeholder="Can-Id"
                    v-model="value5"
                    :style="{ width: canIdWidth }"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="search('canId')"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" style="margin-bottom: 3%">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="9"
                >
                  <vs-input
                    type="text"
                    label-placeholder="Company"
                    v-model="value2"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="search('company')"
                  ></vs-button>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="9"
                >
                  <vs-input
                    type="text"
                    label-placeholder="Mobile"
                    v-model="value3"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="search('mobile')"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" style="margin-bottom: 3%">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="9"
                >
                  <vs-input
                    type="text"
                    label-placeholder="Email"
                    v-model="value4"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="search('email')"
                  ></vs-button>
                </vs-col>
              </vs-row>
            </vs-dropdown-menu>
          </vs-dropdown>
          <vs-button
            radius
            class="gap"
            color="dark"
            type="gradient"
            icon="refresh"
            @click="refreshdata"
          ></vs-button>
        </vs-col>
        <!-- <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
          <vs-dropdown class="gap" vs-custom-content vs-trigger-click>
            <vs-button
              radius
              color="dark"
              type="gradient"
              icon="search"
            ></vs-button>
            <vs-dropdown-menu class="loginx">
              <vs-row vs-w="12" style="margin-bottom: 3%">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="9"
                >
                  <vs-input
                    type="text"
                    label-placeholder="Company"
                    v-model="value2"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="search('company')"
                  ></vs-button>
                </vs-col>
              </vs-row>
            </vs-dropdown-menu>
          </vs-dropdown>
          <vs-button
            radius
            class="gap"
            color="dark"
            type="gradient"
            icon="refresh"
            @click="refreshdata"
          ></vs-button>
        </vs-col>-->
      </vs-row>
      <vs-row style="margin-top: 2%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <b>Search Criteria:</b>
        </vs-col>
        <vs-col
          style="overflow-y: scroll"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="9"
        >
          <div v-for="(chip, index) in searchCriteria" :key="index">
            <vs-chip
              v-if="chip !== 'divider'"
              closable
              @click="removechip(chip)"
              color="primary"
            >
              <b>{{ chip }}</b>
            </vs-chip>
            <div v-else>
              <div class="vl"></div>
            </div>
          </div>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="1"
        >
          <vs-button
            color="dark"
            type="gradient"
            icon="search"
            @click="analyzePagination(1)"
          ></vs-button>
        </vs-col>
      </vs-row>
      <!-- <vs-row style="margin-top: 2%">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="12">
        <vs-button
          style="margin-right: 1%"
          size="small"
          color="dark"
          type="gradient"
          @click="selectall()"
        >{{selecttext}}</vs-button>
        <vs-button
          size="small"
          color="dark"
          type="gradient"
          @click="multipleleads()"
        >Assign Multiple</vs-button>
      </vs-col>
    </vs-row>
    <vs-row style="margin-top: 2%">
      <vs-col>
        <leads-data-table
          :selectall="selectAllOption"
          :selectedleads="selectedleads"
          :totalpagination="tablelinks"
          :search="sortsearch()"
          :totalleadcount="totalleadcount"
        />
      </vs-col>
      </vs-row>-->
      <vs-row style="margin-top: 2%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="12"
        >
          <vs-button size="small" color="dark" @click="selectallItems()">{{
            selectText
          }}</vs-button>
          <vs-button
            size="small"
            color="dark"
            style="margin-left: 1%"
            @click="mulitpleLeadsassign()"
            >Reset</vs-button
          >
          <!-- <vs-input
            class="inputx"
            style="margin-left: 1%; width: 75px"
            placeholder="Count"
            v-model="leadAssignCount"
          /> -->
          <!-- <vs-input-number style="margin-left: 1%" color="dark" v-model="leadAssignCount" /> -->
          <!-- <vs-button
            size="small"
            color="dark"
            style="margin-left: 1%"
            @click="assignwithcount()"
            >Assign with count</vs-button
          > -->
        </vs-col>
      </vs-row>
      <div class="gap"></div>
      <div class="gap">
        <vs-table max-items="10" :data="$store.state.MwbLeads">
          <template slot="thead">
            <vs-th>Select All</vs-th>
            <vs-th> Can-ID</vs-th>
            <vs-th>Name</vs-th>
            <vs-th>Company</vs-th>
            <vs-th>Course</vs-th>
            <vs-th>Level</vs-th>
            <vs-th>SPOC</vs-th>
            <vs-th>Last Call</vs-th>
            <vs-th>Next Call</vs-th>
            <vs-th>View Info</vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody ref="tbody">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <div>
                    <input
                      type="checkbox"
                      :id="indextr"
                      color="dark"
                      v-model="receiptSelection"
                      :value="tr.id"
                    />
                    <!-- <vs-checkbox color="dark" v-model="selectedleadscopy" :vs-value="tr"></vs-checkbox> -->
                  </div>
                </vs-td>
                <vs-td>
                  <div>{{ tr.identity }}</div>
                </vs-td>
                <vs-td :data="tr.email">
                  <vs-row>
                    <vs-col
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="flex-start"
                      vs-w="12"
                    >
                      <vx-tooltip
                        :title="
                          tr.spoc_name + ' (' + tr.engagement_added_on + ')'
                        "
                        :text="tr.engagement_details"
                        >{{ tr.person_name }}</vx-tooltip
                      >
                    </vs-col>
                  </vs-row>
                </vs-td>
                <vs-td>
                  <div>{{ tr.company }}</div>
                </vs-td>
                <vs-td>
                  <div>{{ tr.courses }}</div>
                </vs-td>

                <vs-td>
                  <vs-chip :color="getColor(tr.level)">
                    <b>{{ tr.level }}</b>
                  </vs-chip>
                </vs-td>

                <vs-td>
                  <div>{{ tr.spoc_name }}</div>
                </vs-td>

                <vs-td>
                  <div>{{ unixTimestampConvert(tr.last_call) }}</div>
                  <!-- <div>{{ tr.next_call }}</div> -->
                </vs-td>

                <vs-td>
                  <div>{{ unixTimestampConvert(tr.next_call) }}</div>
                  <!-- <div>{{ tr.next_call }}</div> -->
                </vs-td>

                <vs-td>
                  <vs-row>
                    <div @click="openPopupEmit(tr)">
                      <vs-icon
                        icon="info_outline"
                        size="small"
                        color="dark"
                      ></vs-icon>
                    </div>
                    <!-- <img
                      @click="openPopupEmit(tr)"
                      src="https://img.icons8.com/material-outlined/24/000000/info.png"
                    />-->
                    <!-- <div @click="assignlead(tr)">
                      <vs-icon
                        icon="assignment"
                        size="small"
                        color="dark"
                      ></vs-icon>
                    </div> -->
                    <!-- <img
                      style="margin-left: 10%"
                      
                      src="https://img.icons8.com/ios-filled/24/000000/task.png"
                    />-->
                  </vs-row>
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
      <vs-row style="margin-top: 3%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-end"
          vs-w="12"
        >
          <vs-chip style="margin-right: 1%" color="primary">
            <b>{{ totalNumber }}</b>
          </vs-chip>
          <vs-pagination
            :total="tablelinks"
            v-model="currentTablePage"
          ></vs-pagination>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
import _ from "lodash";
import vSelect from "vue-select";
import DateRangePicker from "vue2-daterange-picker";
import "vue-tree-halower/dist/halower-tree.min.css";
import LeadsNavBar from "../components/pagesComponents/LeadsNavBar.vue";
import LeadsDataTable from "../components/pagesComponents/AssignmentDatatable.vue";
import FocusSearch from "../components/pagesComponents/FocusSearch.vue";
import SpocSearchTree from "../components/pagesComponents/SPOCSearchTree.vue";
import Datepicker from "vuejs-datepicker";
import EventBus from "../components/eventbus.js";
import { VTree, VSelectTree } from "vue-tree-halower";
import axios from "axios";
import constants from "../../constants.json";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "vue-select/dist/vue-select.css";
export default {
  components: {
    Datepicker,
    LeadsNavBar,
    LeadsDataTable,
    VTree,
    VSelectTree,
    FocusSearch,
    SpocSearchTree,
    DateRangePicker,
    Treeselect,
    "v-select": vSelect
  },
  data() {
    return {
      dropdownKey: 0,
      dateRange: {
        startDate: "",
        endDate: ""
      },
      selectAllOption: false,
      totalNumber: 0,
      citiesSearchOptions: [],
      leadAssignCount: "",
      selectedleads: [],
      deselectedleads: [],
      totalleadcount: "",
      nameAutocomplete: [],
      selectText: "Select All",
      selectall: false,
      deselectedValues: [],
      receiptSelection: [],
      receipts: [],
      initSelectedBDE: [],
      initSelectedCourses: [],
      initSelectedLevels: [],
      initSelected: undefined,
      citySearch: "",
      treeDatafocus: [
        // {
        //   id: "All",
        //   label: "All",
        //   children: [
        {
          id: "Delays",
          label: "Delays"
        },
        {
          id: "Today",
          label: "Today"
        },
        {
          id: "Waiting",
          label: "Waiting"
        },
        {
          id: "Untapped",
          label: "Untapped"
        }
        // ]
        // }
      ],
      treeDataBDE: [
        {
          id: "All",
          label: "All",
          children: []
        }
      ],
      treeDataCourses: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "CPA",
              label: "CPA"
            },
            {
              id: "CMA",
              label: "CMA"
            },
            // {
            //   id: "AICPA",
            //   label: "AICPA"
            // }
            // {
            //   id: "DA",
            //   label: "DA"
            // },
            // {
            //   id: "RPA",
            //   label: "RPA"
            // },
            // {
            //   id: "FOF(O)",
            //   label: "FOF(O)"
            // },
            // {
            //   id: "WCBA",
            //   label: "WCBA"
            // },
            // {
            //   id: "NO-BA",
            //   label: "NO-BA"
            // },
            // {
            //   id: "MAYBE",
            //   label: "MAYBE"
            // }
          ]
        }
      ],
      treeDataLevels: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "P1",
              label: "P1",
              children: [
                {
                  id: "M3",
                  label: "M3"
                },
                {
                  id: "M3+",
                  label: "M3+"
                },
                {
                  id: "M3++",
                  label: "M3++"
                },
                {
                  id: "M5",
                  label: "M5"
                },
                {
                  id: "M6",
                  label: "M6"
                }
              ]
            },
            // {
            //   id: "P2",
            //   label: "P2",
            //   children: [
            //     {
            //       id: "L2",
            //       label: "L2"
            //     },
            //     {
            //       id: "L3",
            //       label: "L3"
            //     },
            //     {
            //       id: "L4",
            //       label: "L4"
            //     },
            //     {
            //       id: "L6",
            //       label: "L6"
            //     },
            //     {
            //       id: "L5",
            //       label: "L5"
            //     },
            //     {
            //       id: "L3+",
            //       label: "L3+"
            //     }
            //   ]
            // },
            {
              id: "P3",
              label: "P3",
              children: [
                {
                  id: "M4-",
                  label: "M4-"
                },
                {
                  id: "M4",
                  label: "M4"
                },
                {
                  id: "M2",
                  label: "M2"
                },
                {
                  id: "M1",
                  label: "M1"
                }
                // {
                //   id: "L4-",
                //   label: "L4-"
                // },
                // {
                //   id: "L1",
                //   label: "L1"
                // }
              ]
            }
            // {
            //   id: "M7",
            //   label: "M7"
            // },
            // {
            //   id: "N/A",
            //   label: "N/A"
            // },
            // {
            //   id: "DND",
            //   label: "DND"
            // }
          ]
        }
      ],
      tablelinks: 0,
      currentTablePage: 1,
      spocLogged: false,
      canIdWidth: "100px",
      value1: "",
      value2: "",
      selecttext: "Select All",
      value3: "",
      value4: "",
      value5: "",
      searchIn: {
        id: "",
        name: ""
      },
      rawBde: [],
      infosearch: [],
      searchCriteria: [],
      bulkdata: [],
      count: 0,
      mwb_names: []
    };
  },
  mounted() {
    if (localStorage.getItem("role") === "") {
      localStorage.setItem("role", "cm1");
    }
    this.cm2Redirect();
    this.$store.commit("CLEAR_DASHBOARD_LEADS");
    if (localStorage.getItem("role") === "cm4") {
      this.spocLogged = false;
      this.canIdWidth = "100%";
      this.getSpocAdmin();
    } else {
      this.spocLogged = false;
      this.canIdWidth = "100%";
      this.getSpoc();
    }
    EventBus.$on("radio-change", val => {
      this.initSelected = val;
    });
    EventBus.$on("reload-assignments", () => {
      this.selectall = false;
      this.leadAssignCount = "";
      this.receiptSelection = [];
      this.deselectedValues = [];
      this.analyzePagination(1);
    });
    EventBus.$on("open-email-leadsdatatable", payload => {
      this.bulkdata = [];
      for (let y = 1; y <= 5; y++) {
        this.search_API(y, true, payload);
      }
      this.bulkdata.forEach(bulk => {
        console.log(bulk);
      });
    });
    EventBus.$on("multiple-assign", (payload, payload2) => {
      if (payload.length > 1) {
        EventBus.$emit(
          "assign-lead",
          payload,
          payload2,
          this.selectAllOption,
          this.sortsearch(),
          this.totalleadcount
        );
      } else {
        this.$vs.notify({
          title: "Atleast 2 lead must be selected",
          text: "Atleast 2 leads must be selected for multiple assign",
          color: "danger"
        });
      }
    });
  },
  watch: {
    selectall(value) {
      if (value === true) {
        this.selectText = "Deselect All";
        this.deselectedValues = [];

        let receipts = this.$store.state.MwbLeads;
        receipts.forEach(receiptdata => {
          this.receiptSelection.push(receiptdata.id);
        });
      } else{
        this.selectText = "Select All";
        this.receiptSelection = [];
        this.deselectedValues = [];
      }
      /* switch (value) {
        case true:
          this.selectText = "Deselect All";
          this.deselectedValues = [];

          let receipts = this.$store.state.MwbLeads;
          receipts.forEach(receiptdata => {
            this.receiptSelection.push(receiptdata.id);
          });
          break;
        case false:
          this.selectText = "Select All";
          this.receiptSelection = [];
          this.deselectedValues = [];
      } */
    },
    receiptSelection(value) {
      let receipts = this.$store.state.MwbLeads;
      receipts.forEach(deselect => {
        if (
          value.indexOf(deselect.id) === -1 &&
          this.deselectedValues.indexOf(deselect.id) === -1
        ) {
          this.deselectedValues.push(deselect.id);
        }
      });
    },
    selectAllOption: function(value) {
      if (value) {
        this.selecttext = "Deselect All";
        EventBus.$emit("update-checkbox");
      } else {
        this.selecttext = "Select All";
        EventBus.$emit("uncheck-checkboxes");
      }
    },
    initSelectedBDE: function() {
      this.mergeAllSearch();
    },
    initSelected: function() {
      this.mergeAllSearch();
    },
    initSelectedCourses: function() {
      this.mergeAllSearch();
    },
    initSelectedLevels: function() {
      this.mergeAllSearch();
    },
    currentTablePage: async function(val) {
      this.analyzePagination(val);
    },
    infosearch: function() {
      this.mergeAllSearch();
    }
  },
  methods: {
    multipleleads() {
      EventBus.$emit("get-selected");
    },
    // selectall() {
    //   this.selectAllOption = !this.selectAllOption;
    // },
    selectallItems() {
      this.selectall = !this.selectall;
    },
    autoCompleteName(search, loading) {
      if (search.length < 3) {
        return;
      }
      loading(true);
      this.fetchMwbs(loading, search, this);
    },
    fetchMwbs: _.debounce((loading, search, vm) => {
      let url = `${constants.SERVER_API}searchMwbName?name=${escape(search)}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`
          }
        })
        .then(response => {
          vm.mwb_names = response.data.map(lead => lead.person_name);
          loading(false);
        });
    }, 350),
    openEmail() {
      this.EmitEvent("open-email-bdeleads");
    },
    assignwithcount() {
      if (isNaN(this.leadAssignCount) || this.leadAssignCount === "") {
        this.$vs.notify({
          title: "Should provide a valid number",
          text: "Shoud provide a valid number to use this feature",
          color: "danger"
        });
        return;
      }
      if (parseInt(this.leadAssignCount) < 1) {
        this.$vs.notify({
          title: "Should provide a valid number",
          text: "Shoud provide a valid number to use this feature",
          color: "danger"
        });
        return;
      }
      let selected = [];
      let deselected = [];
      this.$store.state.MwbLeads.forEach(lead => {
        this.receiptSelection.forEach(leadid => {
          if (leadid === lead.id) {
            selected.push(lead);
          }
        });
      });
      this.$store.state.MwbLeads.forEach(lead => {
        this.deselectedValues.forEach(leadid => {
          if (leadid === lead.id) {
            deselected.push(lead);
          }
        });
      });
      EventBus.$emit(
        "assign-lead-count",
        selected,
        deselected,
        this.selectall,
        this.sortsearch(),
        this.totalleadcount,
        this.leadAssignCount
      );
    },
    mulitpleLeadsassign() {
      if (this.receiptSelection.length < 1) {
        this.$vs.notify({
          title: "Select atleast 1 lead",
          text: "Select atleast 1 lead to use this feature",
          color: "danger"
        });
        return;
      }
      let selected = [];
      let deselected = [];
      this.$store.state.MwbLeads.forEach(lead => {
        this.receiptSelection.forEach(leadid => {
          if (leadid === lead.id) {
            selected.push(lead);
          }
        });
      });
      this.$store.state.MwbLeads.forEach(lead => {
        this.deselectedValues.forEach(leadid => {
          if (leadid === lead.id) {
            deselected.push(lead);
          }
        });
      });
      // console.log(selected);
      // console.log(deselected);
      // console.log(this.selectall);
      // console.log(this.sortsearch());
      // console.log(this.totalleadcount);
      this.finalObj = this.sortsearch();
      this.finalObj.select_all_leads_state = this.getResponseValue(
        this.selectall
      );
      this.finalObj.selected_lead_ids = this.getIdsFromArray(selected);
      this.finalObj.excluded_lead_ids = this.getIdsFromArray(deselected);
      console.log(this.finalObj);
      return;

      // this.$vs.loading();

      // let url = `${constants.SERVER_API}`;
      // axios
      //   .post(url, this.finalObj, {
      //     headers: {
      //       Authorization: `Bearer ${localStorage.userAccessToken}`
      //     }
      //   })
      //   .then(response => {
      //     this.$vs.loading.close()
      //     console.log(response);
      //   })
      //   .catch(error => {
      //     this.$vs.loading.close()
      //     this.handleError(error);
      //   });

      //   EventBus.$emit(
      //     "assign-lead-multiple",
      //     selected,
      //     deselected,
      //     this.selectall,
      //     this.sortsearch(),
      //     this.totalleadcount
      //   );
    },
    getResponseValue(selectAllState) {
      if (selectAllState) {
        return "yes";
      } else {
        return "no";
      }
    },
    getResponseValueFocus(value) {
      if (value === undefined) {
        return "";
      } else {
        return value;
      }
    },
    getIdsFromArray(MainArray) {
      let returnArray = [];
      MainArray.forEach(obj => {
        returnArray.push(obj.id);
      });
      return returnArray.join();
    },
    assignlead(tr) {
      EventBus.$emit(
        "assign-lead",
        [tr],
        [],
        this.selectall,
        this.sortsearch(),
        1
      );
    },
    removechip(chip) {
      // console.log(chip);
      // console.log(this.treeDataBDE);
      // console.log(this.treeDataLevels);
      // console.log(this.treeDataCourses);
      // console.log(this.treeDatafocus);
      if (this.infosearch.indexOf(chip) !== -1) {
        this.infosearch.splice(this.infosearch.indexOf(chip), 1);
      }
      let chipPlacement = "";
      if (localStorage.getItem("role") === "cm4") {
        this.treeDataBDE[0].children.forEach(child => {
          child.children.forEach(child2 => {
            if (child2.label === chip) {
              chipPlacement = "bde";
            }
          });
        });
      } else {
        this.treeDataBDE[0].children.forEach(child => {
          if (child.label === chip) {
            chipPlacement = "bde";
          }
        });
      }
      this.treeDataCourses[0].children.forEach(child => {
        if (child.label === chip) {
          chipPlacement = "courses";
        }
      });
      this.treeDataLevels[0].children.forEach(child => {
        if (chip === "M7" || chip === "N/A" || chip === "DND") {
          chipPlacement = "levels";
        } else {
          if ("children" in child) {
            child.children.forEach(chi => {
              if (chi.label === chip) {
                chipPlacement = "levels";
              }
            });
          }
        }
      });
      if (this.initSelected === chip) {
        this.initSelected = undefined;
      }
      switch (chipPlacement) {
        case "bde":
          if (this.initSelectedBDE.indexOf(chip) === -1) {
            if (localStorage.getItem("role") === "cm4") {
              if (this.initSelectedBDE.indexOf("All") !== -1) {
                this.initSelectedBDE = [];
                this.treeDataBDE[0].children.forEach(child => {
                  child.children.forEach(child2 => {
                    if (child2.label !== chip) {
                      this.initSelectedBDE.push(child2.label);
                    }
                  });
                });
              } else {
                let city = "";
                this.treeDataBDE[0].children.forEach(child => {
                  child.children.forEach(child2 => {
                    if (child2.label === chip) {
                      city = child.label;
                    }
                  });
                });
                this.initSelectedBDE.splice(
                  this.initSelectedBDE.indexOf(city),
                  1
                );
                this.treeDataBDE[0].children.forEach(child => {
                  if (child.label === city) {
                    child.children.forEach(child2 => {
                      if (child2.label !== chip) {
                        this.initSelectedBDE.push(child2.label);
                      }
                    });
                  }
                });
              }
            } else {
              this.initSelectedBDE = [];
              this.treeDataBDE[0].children.forEach(child => {
                if (child.label !== chip) {
                  this.initSelectedBDE.push(child.label);
                }
              });
            }
          } else {
            this.initSelectedBDE.splice(this.initSelectedBDE.indexOf(chip), 1);
          }
          break;
        case "courses":
          if (this.initSelectedCourses.indexOf(chip) === -1) {
            this.initSelectedCourses = [];
            this.treeDataCourses[0].children.forEach(child => {
              if (child.label !== chip) {
                this.initSelectedCourses.push(child.label);
              }
            });
          } else {
            this.initSelectedCourses.splice(
              this.initSelectedCourses.indexOf(chip),
              1
            );
          }
          break;
        case "levels":
          if (this.initSelectedLevels.indexOf("All") !== -1) {
            this.initSelectedLevels = [];
            this.treeDataLevels[0].children.forEach(child => {
              child.children.forEach(chi => {
                if (chi.label !== chip) {
                  this.initSelectedLevels.push(chi.label);
                }
              });
            });
          } else if (this.initSelectedLevels.indexOf(chip) !== -1) {
            this.initSelectedLevels.splice(
              this.initSelectedLevels.indexOf(chip),
              1
            );
          } else {
            let childProperty = "";
            this.treeDataLevels[0].children.forEach(child => {
              if ("children" in child) {
                child.children.forEach(chi => {
                  if (chi.label === chip) {
                    childProperty = child.label;
                  }
                });
              }
            });
            this.initSelectedLevels.splice(
              this.initSelectedLevels.indexOf(childProperty),
              1
            );
            this.treeDataLevels[0].children.forEach(child => {
              if (child.label === childProperty) {
                if ("children" in child) {
                  child.children.forEach(chi => {
                    if (chi.label !== chip) {
                      this.initSelectedLevels.push(chi.label);
                    }
                  });
                }
              }
            });
          }
      }
    },
    analyzePagination(val) {
      if (this.currentTablePage === 1) {
        this.search_API(1);
      } else {
        this.search_API(val);
      }
    },
    async search_API(page, copyfor) {
      let focus = this.initSelected;
      if (this.initSelected !== null && this.initSelected !== undefined) {
        focus = this.initSelected.toLowerCase();
      }
      let levels = [];
      let bde = [];
      let courses = [];
      // this.initSelectedBDE.forEach(sort => {
      //   if (sort === "All") {
      //     this.treeDataBDE[0].children.forEach(child => {
      //       bde.push(child.label);
      //     });
      //   } else {
      //     bde.push(sort);
      //   }
      // });
      if (localStorage.getItem("role") === "cm4") {
        this.initSelectedBDE.forEach(sort => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach(child => {
              child.children.forEach(acutal_spoc => {
                bde.push(acutal_spoc.label);
              });
            });
          } else {
            let city = false;
            this.treeDataBDE[0].children.forEach(child => {
              if (child.label === sort) {
                child.children.forEach(acutal_spoc => {
                  bde.push(acutal_spoc.label);
                });
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              bde.push(sort);
            }
          }
        });
      } else {
        this.initSelectedBDE.forEach(sort => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach(child => {
              bde.push(child.label);
            });
          } else {
            bde.push(sort);
          }
        });
      }
      this.initSelectedCourses.forEach(sort => {
        if (sort === "All") {
          this.treeDataCourses[0].children.forEach(child => {
            courses.push(child.label);
          });
        } else {
          courses.push(sort);
        }
      });
      this.initSelectedLevels.forEach(sort => {
        if (sort === "All") {
          this.treeDataLevels[0].children[0].children.forEach(child => {
            levels.push(child.label);
          });
          this.treeDataLevels[0].children[1].children.forEach(child => {
            levels.push(child.label);
          });
          this.treeDataLevels[0].children[2].children.forEach(child => {
            levels.push(child.label);
          });
        } else if (sort === "P1") {
          this.treeDataLevels[0].children[0].children.forEach(child => {
            levels.push(child.label);
          });
        } else if (sort === "P2") {
          this.treeDataLevels[0].children[1].children.forEach(child => {
            levels.push(child.label);
          });
        } else if (sort === "P3") {
          this.treeDataLevels[0].children[2].children.forEach(child => {
            levels.push(child.label);
          });
        } else {
          levels.push(sort);
        }
      });
      let bdeid = [];
      bde.forEach(spoc => {
        this.rawBde.forEach(raw => {
          if (raw.first_name === spoc) {
            bdeid.push(raw.id);
          }
        });
      });
      // let visitors = "";
      // let net_enquiry = "";
      // let eligibility = "";
      // let reference = "";
      // let loan = "";
      // let corporate = "";
      // let university = "";
      // this.infosearch.forEach(value => {
      //   switch (value) {
      //     case "Visitors":
      //       visitors = "Yes";
      //       break;
      //     case "Net Enquiry":
      //       net_enquiry = "Yes";
      //       break;
      //     case "Eligibility":
      //       eligibility = "Yes";
      //       break;
      //     case "Reference":
      //       reference = "Yes";
      //       break;
      //     case "Loan":
      //       loan = "Yes";
      //       break;
      //     case "Corporate":
      //       corporate = "Yes";
      //       break;
      //     case "University":
      //       university = "Yes";
      //       break;
      //   }
      // });
      // let searchIdentity = "";
      // if (this.value5 !== "") {
      //   searchIdentity = `${this.spoc_city.charAt(0)}-${this.value5}`;
      // }
      let obj = {
        courses: courses.join(),
        levels: levels.join(),
        my_focus: this.getResponseValueFocus(focus),
        spoc_ids: bdeid.join(),
        name: this.value1,
        company: this.value2,
        mobile: this.value3,
        email: this.value4,
        identity: this.value5,
        city: this.citySearch
        // visitors: visitors,
        // net_enquiry: net_enquiry,
        // eligibility: eligibility,
        // reference: reference,
        // loan: loan,
        // corporate: corporate,
        // university: university
      };
      if (localStorage.getItem("role") !== "cm4" && this.value5 !== "") {
        obj.identity = `${this.SpocPrefilled}-${this.value5}`;
      }
      if (this.dateRange.startDate !== "" && this.dateRange.endDate !== "") {
        obj.from_date = this.datatoTimestamp(this.dateRange.startDate);
        obj.to_date = this.datatoTimestamp(this.dateRange.endDate);
      }
      // console.log(obj);
      if (copyfor === true) {
        axios
          .post(
            `${constants.SERVER_API}getSearchedMwbLeadsForAssignment?page=${page}`,
            obj,
            {
              headers: {
                Authorization: `Bearer ${localStorage.userAccessToken}`
              }
            }
          )
          .then(response => {
            this.totalNumber = response.data.total;
            response.data.data.forEach(lead => {
              if (this.bulkdata.length !== 50) {
                this.bulkdata.push(lead);
              }
            });
            this.checkBoxesLogic(response.data);
          })
          .catch(error => {
            // if (error.response) {
            //   console.log("error", error.response.status);
            //   if (error.response.status === 401) {
            //     this.ReDirect_to_login();
            //   }
            // }
            this.handleError(error);
          });
      } else {
        // console.log(obj);
        axios
          .post(
            `${constants.SERVER_API}getSearchedMwbLeadsForAssignment?page=${page}`,
            obj,
            {
              headers: {
                Authorization: `Bearer ${localStorage.userAccessToken}`
              }
            }
          )
          .then(response => {
            this.checkBoxesLogic(response.data);
            this.totalNumber = response.data.total;
            // this.totalleadcount = response.data.total;
            response.data.data.forEach(lead => {
              lead.engagements = [];
              lead.mobile = [];
              lead.emails = [];
            });
            this.currentTablePage = response.data.current_page;
            this.tablelinks = response.data.last_page;
            this.$store.commit("MOUNT_MWB_LEADS", response.data.data);
            if (this.selectAllOption === true) {
              EventBus.$emit("update-checkboxes-with-desselect");
            } else {
              EventBus.$emit("push-selected");
            }
          })
          .catch(error => {
            // if (error.response) {
            //   console.log("error", error.response.status);
            //   if (error.response.status === 401) {
            //     this.ReDirect_to_login();
            //   }
            // }
            this.handleError(error);
          });
      }
    },
    checkBoxesLogic(response) {
      if (this.selectall === true) {
        response.data.forEach(responsedata => {
          if (
            this.receiptSelection.indexOf(responsedata.id) === -1 &&
            this.deselectedValues.indexOf(responsedata.id) === -1
          ) {
            this.receiptSelection.push(responsedata.id);
          }
        });
      }
      // console.log("Selected Values => ", this.receiptSelection);
      // console.log("Deselected Value => ", this.deselectedValues);
    },
    additional(emit, data) {
      this.openPopupEmit(data, emit);
      // EventBus.$emit("select-call-section");
    },
    async openPopupEmit(payload, emit) {
      this.$vs.loading({
        type: "radius"
      });
      let url = `${constants.SERVER_API}getSingleMwbLead?can_id=${payload.can_id}&person_id=${payload.person_id}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          this.$store.commit("UPDATE_NEW_DATA", [
            payload,
            response.data.engagements,
            response.data.mobile,
            response.data.emails
          ]);
          this.$vs.loading.close();
          EventBus.$emit("open-student-details-popup", payload);
          if (emit !== undefined) {
            EventBus.$emit(emit);
          }
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    getColor(level) {
      switch (level) {
        case "M1":
          return "danger";
        case "M2":
          return "danger";
        case "M3":
          return "success";
        case "M3+":
          return "success";
        case "M3++":
          return "success";
        case "M4-":
          return "danger";
        case "M4":
          return "danger";
        case "M5":
          return "success";
        case "M6":
          return "success";
        case "L1":
          return "danger";
        case "L2":
          return "warning";
        case "L3":
          return "warning";
        case "L3+":
          return "success";
        case "L4-":
          return "danger";
        case "L4":
          return "warning";
        case "L5":
          return "success";
        case "L6":
          return "success";
        default:
          return "primary";
      }
    },
    sortsearch() {
      let levels = [];
      let bde = [];
      let courses = [];
      // this.initSelectedBDE.forEach(sort => {
      //   if (sort === "All") {
      //     this.treeDataBDE[0].children.forEach(child => {
      //       bde.push(child.label);
      //     });
      //   } else {
      //     bde.push(sort);
      //   }
      // });
      if (localStorage.getItem("role") === "cm4") {
        this.initSelectedBDE.forEach(sort => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach(child => {
              child.children.forEach(acutal_spoc => {
                bde.push(acutal_spoc.label);
              });
            });
          } else {
            let city = false;
            this.treeDataBDE[0].children.forEach(child => {
              if (child.label === sort) {
                child.children.forEach(acutal_spoc => {
                  bde.push(acutal_spoc.label);
                });
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              bde.push(sort);
            }
          }
        });
      } else {
        this.initSelectedBDE.forEach(sort => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach(child => {
              bde.push(child.label);
            });
          } else {
            bde.push(sort);
          }
        });
      }
      this.initSelectedCourses.forEach(sort => {
        if (sort === "All") {
          this.treeDataCourses[0].children.forEach(child => {
            courses.push(child.label);
          });
        } else {
          courses.push(sort);
        }
      });
      this.initSelectedLevels.forEach(sort => {
        if (sort === "All") {
          this.treeDataLevels[0].children[0].children.forEach(child => {
            levels.push(child.label);
          });
          this.treeDataLevels[0].children[1].children.forEach(child => {
            levels.push(child.label);
          });
          this.treeDataLevels[0].children[2].children.forEach(child => {
            levels.push(child.label);
          });
        } else if (sort === "P1") {
          this.treeDataLevels[0].children[0].children.forEach(child => {
            levels.push(child.label);
          });
        } else if (sort === "P2") {
          this.treeDataLevels[0].children[1].children.forEach(child => {
            levels.push(child.label);
          });
        } else if (sort === "P3") {
          this.treeDataLevels[0].children[2].children.forEach(child => {
            levels.push(child.label);
          });
        } else {
          levels.push(sort);
        }
      });
      let bdeid = [];
      bde.forEach(spoc => {
        this.rawBde.forEach(raw => {
          if (raw.first_name === spoc) {
            bdeid.push(raw.id);
          }
        });
      });
      let obj = {
        courses: courses.join(),
        levels: levels.join(),
        spoc_ids: bdeid.join(),
        name: this.value1,
        company: this.value2,
        my_focus: this.initSelected,
        city: this.citySearch,
        mobile: this.value3,
        email: this.value4,
        identity: this.value5,
      };
      if (this.dateRange.startDate !== "" && this.dateRange.endDate !== "") {
        obj.from_date = this.datatoTimestamp(this.dateRange.startDate);
        obj.to_date = this.datatoTimestamp(this.dateRange.endDate);
      }
      return obj;
    },
    refreshdata() {
      EventBus.$emit("refresh-focus");
      this.dateRange.startDate = "";
      this.dateRange.endDate = "";
      this.value1 = "";
      this.value2 = "";
      this.value3 = "";
      this.value4 = "";
      this.value5 = "";
      this.initSelected = undefined;
      this.infosearch = [];
      this.selectall = false;
      this.leadAssignCount = "";
      this.receiptSelection = [];
      this.deselectedValues = [];
      this.initSelectedBDE = [];
      this.initSelectedCourses = [];
      this.initSelectedLevels = [];
      this.getSpocAdmin();
    },
    getSpoc() {
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(async response => {
          this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          this.rawBde = response.data.spocs;
          this.treeDataBDE[0].children = [];
          response.data.spocs.forEach(data => {
            var obj = {
              id: data.first_name,
              label: data.first_name
            };
            this.treeDataBDE[0].children.push(obj);
          });
          this.initSelectedBDE.push(this.searchIn.name);
          this.search_API(1);
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    getSpocAdmin() {
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          response.data.spocs = this.removeUnwwantedSpocs(response.data.spocs);
          this.rawBde = response.data.spocs;
          this.treeDataBDE[0].children = [];
          this.rawcities = [];
          var unique = response.data.spocs
            .map(name => {
              return {
                count: 1,
                name: name.city
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              this.rawcities.push(key);
              this.citiesSearchOptions.push(key);
              var obj = {
                id: key,
                label: key,
                children: []
              };
              this.treeDataBDE[0].children.push(obj);
            }
          }
          response.data.spocs.forEach(server_spoc => {
            this.treeDataBDE.forEach(bde => {
              bde.children.forEach(bdechid => {
                if (bdechid.label === server_spoc.city) {
                  var obj = {
                    id: server_spoc.first_name,
                    label: server_spoc.first_name
                  };
                  bdechid.children.push(obj);
                }
              });
            });
          });
          this.treeDataBDE[0].children = this.sortNestedArrayAlphabetically(
            this.treeDataBDE[0].children
          );
          if (this.rawBde.indexOf(this.searchIn) !== -1) {
            this.initSelectedBDE.push(this.searchIn.name);
          }
          // else {
          // this.initSelectedBDE.push(this.treeDataBDE[0].children[0].label);
          // }
          this.search_API(1);
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    mergeAllSearch() {
      this.searchCriteria = [];
      this.searchCriteria.push("divider");
      if (localStorage.getItem("role") === "cm4") {
        this.initSelectedBDE.forEach(sort => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach(child => {
              child.children.forEach(acutal_spoc => {
                this.searchCriteria.push(acutal_spoc.label);
              });
            });
          } else {
            let city = false;
            this.treeDataBDE[0].children.forEach(child => {
              if (child.label === sort) {
                child.children.forEach(acutal_spoc => {
                  this.searchCriteria.push(acutal_spoc.label);
                });
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              this.searchCriteria.push(sort);
            }
          }
        });
      } else {
        this.initSelectedBDE.forEach(sort => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach(child => {
              this.searchCriteria.push(child.label);
            });
          } else {
            this.searchCriteria.push(sort);
          }
        });
      }
      this.searchCriteria.push("divider");
      this.initSelectedCourses.forEach(sort => {
        if (sort === "All") {
          this.treeDataCourses[0].children.forEach(child => {
            this.searchCriteria.push(child.label);
          });
        } else {
          this.searchCriteria.push(sort);
        }
      });
      this.searchCriteria.push("divider");
      this.initSelectedLevels.forEach(sort => {
        if (sort === "All") {
          this.treeDataLevels[0].children[0].children.forEach(child => {
            this.searchCriteria.push(child.label);
          });
          this.treeDataLevels[0].children[1].children.forEach(child => {
            this.searchCriteria.push(child.label);
          });
          this.treeDataLevels[0].children[2].children.forEach(child => {
            this.searchCriteria.push(child.label);
          });
        } else if (sort === "P1") {
          this.treeDataLevels[0].children[0].children.forEach(child => {
            this.searchCriteria.push(child.label);
          });
        } else if (sort === "P2") {
          this.treeDataLevels[0].children[1].children.forEach(child => {
            this.searchCriteria.push(child.label);
          });
        } else if (sort === "P3") {
          this.treeDataLevels[0].children[2].children.forEach(child => {
            this.searchCriteria.push(child.label);
          });
        } else {
          this.searchCriteria.push(sort);
        }
      });
      this.searchCriteria.push("divider");
      // this.initSelected.forEach(sort => {
      //   if (sort === "All") {
      //     this.treeDatafocus[0].children.forEach(child => {
      //       this.searchCriteria.push(child.label);
      //     });
      //   } else {
      //     this.searchCriteria.push(sort);
      //   }
      // });
      if (this.initSelected !== "" && this.initSelected !== undefined) {
        this.searchCriteria.push(this.initSelected);
      }
      this.searchCriteria.push("divider");
      this.infosearch.forEach(info => {
        this.searchCriteria.push(info);
      });
    },
    clear() {
      this.value1 = "";
      this.value2 = "";
    },
    search() {
      this.dropdownKey = Math.random()
      this.search_API();
    },
    nodechecked(node, v) {
      alert("that a node-check envent ..." + node.title + v);
    },
    async asyncLoad(node) {
      console.log("clicked");
      const { checked = false } = node;
      this.$set(node, "loading", true);
      let pro = await new Promise(resolve => {
        setTimeout(resolve, 2000, [
          { title: "test1", async: true },
          { title: "test2", async: true },
          { title: "test3" }
        ]);
      });
      if (!node.hasOwnProperty("children")) {
        this.$set(node, "children", []);
      }
      node.children.push(...pro);
      this.$set(node, "loading", false);
      if (checked) {
        this.$refs.tree.childCheckedHandle(node, checked);
      }
    },
    showTreeBox() {
      console.log("clicked");
      return this.treeDataBDE;
    },
    consolelog() {
      console.log("clicked");
    },
    EmitEvent(emit, payload) {
      if (payload === "") {
        EventBus.$emit(emit);
      } else {
        EventBus.$emit(emit, payload);
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/pages/dropdown.scss";
.headr {
  color: gray;
  font-size: 25px;
  margin-bottom: 2%;
}
.gap {
  margin-bottom: 4%;
}
.inicolor {
  color: gray;
}
.cardtext {
  color: gray;
  font-size: 20px;
}
.tree3 {
  // float: left;
  // width: 33%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow: auto;
  height: 500px;
  width: 300px;
}
.vs-dropdown--menu {
  width: 300px;
  // height: 500px;
  // overflow: hidden;
}
.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gap {
  margin-right: 1%;
}
.examplex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
i {
  font-size: 18px;
}
.vl {
  border-left: 1px solid black;
  height: 35px;
}
</style>
